// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'bootstrap-datepicker';
import Rails from '@rails/ujs';
import jQuery from 'jquery';
import 'jquery-form';
import 'chosen-js';

import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.css';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.css';
import 'chosen-js/chosen.css';

import 'channels';
import 'global';

// https://rubyyagi.com/how-to-use-bootstrap-and-jquery-in-rails-6-with-webpacker/
global.$ = global.jQuery = jQuery;
Rails.start();

$(() => {
  $('.datepicker').datepicker({
    format: 'mm/dd/yyyy'
  });

  $('.chzn-select').chosen({
    allow_single_deselect: true,
    no_results_text: 'No results matched'
  });
});
