import './ckeditor4_basepath';
import 'ckeditor4';
import 'ckeditor4/adapters/jquery';

CKEDITOR.config.filebrowserBrowseUrl = '/ckeditor/attachment_files';
CKEDITOR.config.filebrowserFlashBrowseUrl = '/ckeditor/attachment_files';
CKEDITOR.config.filebrowserFlashUploadUrl = '/ckeditor/attachment_files';
CKEDITOR.config.filebrowserImageBrowseLinkUrl = '/ckeditor/pictures';
CKEDITOR.config.filebrowserImageBrowseUrl = '/ckeditor/pictures';
CKEDITOR.config.filebrowserImageUploadUrl = '/ckeditor/pictures?';
CKEDITOR.config.filebrowserUploadUrl = '/ckeditor/attachment_files';
CKEDITOR.config.filebrowserUploadMethod = 'form';
CKEDITOR.config.allowedContent = true;
CKEDITOR.config.extraPlugins = 'colorbutton';
